import { createSlice } from "@reduxjs/toolkit";

const chatDataSlice = createSlice({
  name: "chatData",
  initialState: {
    isOnline: false,
    chatsData: [],
  },
  reducers: {
    setChats: (state, action) => {
      state.chatsData = action.payload;
    },
    addMessage: (state, action) => { // Fixed syntax here
      // console.log(action.payload);
      // Assuming you want to push a new message into `chatsData`
      // state.chatsData.push(action.payload); // Example logic
    },
  },
});

// Export both actions
export const { setChats, addMessage } = chatDataSlice.actions;
export default chatDataSlice.reducer;
