import * as React from 'react';
import Paper from '@mui/material/Paper';

import { Autocomplete, Box, Button, Fab, Grow, LinearProgress, Modal, TextField, Typography, } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import CreateSquad from '../../inc/CreateSquad';
import { useAddWaitingMemberInSquadMutation, useSearchSquadByNameQuery } from '../../Store/api/apiSlice';
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import LoadingAnimation from '../../inc/LoadingAnimation';


export default function JoinSquad() {
    const [selectedSquad, setSelectedSquad] = React.useState({
        label: ""
    })
    const buyer = useSelector(state => state.buyer)
    const [addMemberInSquadWaiting, { isLoading: memberAddWaiting, isSuccess: memberAddIsSuccess, error: memberAddIsError }] = useAddWaitingMemberInSquadMutation();
    const [searchString, setSearchString] = React.useState(String)
    const [searchList, setSearchList] = React.useState([])
    const { isLoading, data } = useSearchSquadByNameQuery(searchString, {
        skip: searchString === undefined || searchString.length < 3
    })
    const [showModal, setModalShow] = React.useState(false)
    const [showSquadDetailsModal, setSquadDetailsModalShow] = React.useState(false)
    const [isVideoLoaded, setIsVideoLoaded] = React.useState(false);
    const { user } = useAuth0()
    const navigate = useNavigate();
    const options = [
        { id: "1", label: "Near IT Park", color: "#1e3264", imageUrl: "/gif/vlogger.gif", shortCode: 'IT' },
        { id: "2", label: "Cricket", color: "#5f8108", imageUrl: "/gif/cricket.gif", shortCode: 'Cricket' },
        { id: "3", label: "Just Married", color: "#db148b", imageUrl: "/gif/wedding.gif" },
        { id: "4", label: "Investment", color: "#b02897", imageUrl: "/gif/financial-advisor.gif" },
        { id: "5", label: "Luxury", color: "#a56752", imageUrl: "/gif/ruby.gif" },
        { id: "6", label: "NRI", color: "#b95d06", imageUrl: "/gif/trip.gif" },
        { id: "7", label: "Eco-Conscious", color: "#1e3264", imageUrl: "/gif/green-planet.gif" },
        { id: "8", label: "Joint Family", color: "#8e66ac", imageUrl: "/gif/vlogger.gif" },
        { id: "9", label: "Gujrati", color: "#777777", imageUrl: "/gif/vlogger.gif" },
        { id: "10", label: "Jain", color: "#1e3264", imageUrl: "/gif/vlogger.gif" },
    ];

    React.useEffect(() => {
        const namelist = []
        if (data) {
            data.map(
                (squad) => {
                    namelist.push({
                        label: squad?.squadName,
                        id: squad?._id,
                        squadPitchUrl: squad.squadPitchUrl
                    })
                }
            )
        }
        setSearchList(namelist)
        // console.log(namelist);

    }, [data])
    // React.useEffect(() => {
    //     if (selectedSquad !== null) {
    //         console.log("navigate to" + selectedSquad?.id);
    //     }

    // }, [selectedSquad])
    const handleVibeClick = (vibe) => {
        navigate('/join-squad/' + vibe);

    }
    const handleOpenModal = () => {
        setModalShow(true)
    }
    const handleCloseModal = () => {
        setModalShow(false)
    }
    const handleOpenSquadDEtailsModal = () => {
        setSquadDetailsModalShow(true)
    }
    const handleCloseSquadDEtailsModal = () => {
        setSquadDetailsModalShow(false)
    }
    const handleJoinSquadRequest = () => {
        if (selectedSquad?.id) {
            const squadId = selectedSquad?.id
            addMemberInSquadWaiting({ squadId, buyer })
        }
    }
    const handleVideoLoaded = () => {
        setIsVideoLoaded(true);
    };
    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ height: 60, paddingTop: 5, paddingBottom: 1, }}>
                <Grow in><Typography color="#708ad4" variant='h5'>Hello {user?.given_name || "there"},</Typography></Grow>
                <Grow in><Typography color="#708ad4" variant='subtitle2' >Let's find your future neighbours</Typography></Grow>

            </Box>

            <Box
                component="form"
                sx={{ display: 'flex', alignItems: 'center', flexDirection: "column", width: "100%", borderRadius: 30, justifyContent: "center" }}
            >
                <Grid2 container sx={{ width: { xs: "80%", md: "30%" }, paddingX: { xs: 10 }, alignSelf: "center", borderRadius: 2, paddingX: 2, paddingY: 1, boxShadow: "rgba(50, 50, 93, 0.1) 0px 0px 50px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -1px 0px 0px inset" }}>

                    <Grid2 item xs={12} sx={{ display: 'flex', flexDirection: "column", alignItems: 'center', width: "100%", pb: 1 }}>
                        {isLoading &&
                            <LinearProgress sx={{
                                backgroundImage: "linear-gradient(90deg, #d0368a 0%, #708ad4 99%)",
                                boxShadow: "inset 2px 2px 5px 0 rgba(255, 255, 255, 0.5)", // rgba for white color
                                borderRadius: "100px",
                                width: "100%",
                                alignSelf: "center",
                            }} />
                        }
                        <Autocomplete
                            disablePortal
                            value={selectedSquad}
                            options={searchList}
                            onChange={(event, newValue) => setSelectedSquad(newValue)}
                            sx={{ width: 300, borderRadius: 30, px: 3, flex: 1, width: "100%", mb: 3 }}

                            renderInput={(params) => <TextField

                                onChange={(e) => { setSearchString(e.target.value) }}  {...params}
                                label="Search with Squad Name"
                                variant="standard"
                            />}
                        />

                        {
                            selectedSquad?.id !== undefined && <Button
                                onClick={handleOpenSquadDEtailsModal}
                                sx={{
                                    backgroundImage: "linear-gradient(90deg, #d0368a 0%, #708ad4 99%)",
                                    color: "white",
                                    textTransform: "none"

                                }}>
                                Play Squad Video
                            </Button>

                        }
                    </Grid2>
                </Grid2>

            </Box>
            <Box sx={{ mt: 7 }}>
                <Typography color="#708ad4" variant="body2" sx={{ textAlign: 'center', marginY: 2 }}>
                    Or choose "The Vibe"
                </Typography>

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        height: "25%",
                        overflowX: "auto",   // Enable horizontal scroll
                        whiteSpace: "nowrap", // Prevent wrapping of items
                        marginTop: 2,
                        gap: 2,
                        pb: 1,
                        scrollSnapType: "x mandatory", // Optional: Enable scroll snapping
                        "&::-webkit-scrollbar": {
                            width: "5px",
                            maxHeight: 10,
                        },
                        "&::-webkit-scrollbar-thumb": {
                            backgroundImage: "linear-gradient(90deg, #d0368a 0%, #708ad4 99%)",
                            boxShadow: "inset 2px 2px 5px 0 rgba(255, 255, 255, 0.5)",
                            borderRadius: "100px",
                        },
                    }}
                >
                    {options.map((category) => {
                        return (
                            <Paper
                                key={category?.id}
                                onClick={() => handleVibeClick(category?.shortCode)}
                                sx={{
                                    backgroundImage: "linear-gradient(0deg, #708ad4 0%, #fff 99%)",
                                    color: "white",
                                    paddingTop: 2,
                                    paddingLeft: 2,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    height: 100,
                                    zIndex: 0,
                                    overflow: "hidden",
                                    flexShrink: 0,
                                    scrollSnapAlign: "start",
                                    width: 200,
                                }}
                            >
                                <Box
                                    sx={{
                                        position: "relative", // Container for the image with relative positioning
                                        width: "50px",
                                        height: "50px",
                                        alignSelf: "center",
                                    }}
                                >
                                    <img
                                        style={{
                                            height: "100%",
                                            transform: "rotate(0deg)",
                                            zIndex: -10,
                                            position: "absolute",
                                            bottom: 0,
                                            right: 10,
                                            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                                            borderRadius: 5,

                                        }}
                                        src={category?.imageUrl}
                                        alt=""
                                    />
                                </Box>
                                <Typography sx={{ textAlign: "center", paddingTop: 2 }} variant="h6">
                                    {category?.label}
                                </Typography>
                            </Paper>
                        );
                    })}
                </Box>
                <Box
                    sx={{
                        m: 2,

                    }}
                >
                    <Typography color="#708ad4" variant="subtitle1" sx={{ textAlign: 'center', marginY: 2 }}>
                        Or Create Your Own Squad
                    </Typography>
                    <Fab sx={{
                        backgroundImage: "linear-gradient(90deg, #d0368a 0%, #708ad4 99%)",
                        color: "white"
                    }}
                        onClick={handleOpenModal}
                    >
                        <Add />
                    </Fab>
                    <Modal
                        open={showModal}
                        onClose={handleCloseModal}
                        sx={{display:"flex",justifyContent:"center"}}
                    >
                        <CreateSquad />
                    </Modal>
                    <Modal
                        open={showSquadDetailsModal}
                        onClose={handleCloseSquadDEtailsModal}
                    >
                        {!isVideoLoaded ? <LoadingAnimation /> :
                        
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            height: "80%",
                            width: "100%",
                            pt: 1,
                            position: "absolute",
                            bottom: 110
                        }}>
                            
                            {selectedSquad?.squadPitchUrl && (
                                <video
                                    autoPlay
                                    muted={false}
                                    controls
                                    onLoadedData={handleVideoLoaded}  // Video loaded event handler
                                    style={{
                                        boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
                                        maxHeight: 500,
                                        maxWidth: 300,
                                        width: 300,
                                        height: 500,
                                        display: isVideoLoaded ? "block" : "none",  // Hide video until loaded
                                    }}
                                    src={selectedSquad.squadPitchUrl}
                                />
                            )}
                            <Box sx={{ backgroundColor: "white", width: "100%", display: "flex", flexDirection: "column", minHeight: 200, mt: 2, pt: 2 }}>
                                <Box sx={{ minHeight: 60, display: "flex", justifyContent: "center" }}>
                                    {
                                        memberAddWaiting ?
                                            <Box sx={{ display: "flex", justifyContent: "center", width: 250, mt: 2, py: 1, alignSelf: "center" }}>
                                                <LoadingButton loading={true} />
                                                <Typography>Requesting</Typography>
                                            </Box> :
                                            memberAddIsSuccess ?
                                                <Box sx={{ textAlign: "center", mt: 2, py: 1 }}>
                                                    <Typography >Request Sent</Typography>
                                                </Box> :
                                                memberAddIsError &&
                                                <Typography sx={{ color: "red", mt: 2, py: 1, alignSelf: "center" }} >Error ! Please try again.</Typography>

                                    }
                                </Box>
                                <Box sx={{ display: "flex", justifyContent: "center", gap: 3 }}>
                                    <Button
                                        onClick={handleJoinSquadRequest}
                                        sx={{
                                            backgroundImage: "linear-gradient(90deg, #d0368a 0%, #708ad4 99%)",
                                            color: "white",
                                            textTransform: "none",
                                            width: 200,
                                            alignSelf: "center",

                                        }}>
                                        Request To Join
                                    </Button>
                                    <Button
                                        variant='outlined'
                                        onClick={handleCloseSquadDEtailsModal}
                                        sx={{
                                            textTransform: "none",
                                            width: 100,
                                            alignSelf: "center",

                                        }}>
                                        Cancel
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                        }
                    </Modal>
                </Box>

            </Box>

        </Box>
    );
}
