import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({ baseUrl: "https://fast-api-manual-image-199724860551.asia-south1.run.app/" }),
    // baseQuery: fetchBaseQuery({ baseUrl: "http://127.0.0.1:8000" }),
    tagTypes: ['Properties', 'Squad', 'Buyer', 'SquadDeals', 'Vibe', 'PartnerBusinessData'],
    endpoints: (builder) => ({
        getProperties: builder.query({
            query: () => '/properties',
            providesTags: ['Properties'],
        }),
        getPropertyById: builder.query({
            query: (id) => `/properties/${id}`,
            providesTags: (result, error, id) => [{ type: 'Properties', id }],
        }),
        getBuyerById: builder.query({
            query: (id) => `/buyer/email/${id}`,
            providesTags: (result, error, id) => [{ type: 'Buyer', id }],
        }),
        createBuyer: builder.mutation({
            query: (body) => ({
                url : '/buyer/',
                method : 'POST',
                body
            }),
            invalidatesTags: ['Buyer'],
        }),
        updateBuyer: builder.mutation({
            query: ({id,body}) => ({
                url : `/buyer/update/${id}`,
                method : 'PATCH',
                body : body
            }),
            invalidatesTags: ['Buyer'],
        }),
        
        getSquadById: builder.query({
            query: (id) => `/squad/${id}`,
            providesTags: (result, error, id) => [{ type: 'Squad', id }],
        }),
        getSquadDealsBySquadId: builder.query({
            query: (id) => `/deal/squad/${id}`,
            providesTags: (result, error, id) => [{ type: 'SquadDeals', id }],
        }),
        SquadByVibe : builder.mutation({
            query : (body) => ({
                url : '/squad/vibe/',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Vibe'],
        }),
        addWaitingMemberInSquad: builder.mutation({
            query: ({ squadId, buyer }) => ({
                url: `/squad/create/${squadId}/members/waiting`,
                method: 'POST',
                body: buyer ,
            }),
            invalidatesTags: ['Vibe'],
        }),
        getChatsByUserId: builder.query({
            query: (userId) => `/chatData/get-chats-for-user/${userId}`,
            providesTags: (result, error, id) => [{ type: 'chatData', id }]
        }),
        addMsg: builder.mutation({
            query: ({ chatId, msg }) => ({
                url: `chatData/append-message/${chatId}`, // Use chatId dynamically in the URL
                method: 'POST',
                body: msg, // Use body key to send data in the POST request
            }),
        }),
        createReferral: builder.mutation({
            query: (body) => ({
                url: `referral`, // Use chatId dynamically in the URL
                method: 'POST',
                body: body, // Use body key to send data in the POST request
            }),
        }),     
        getPartnerBusinessData: builder.query({
            query: (email) => `businessData/email/${email}`, 
        }),
        createAreaVisit: builder.mutation({
            query: (body) => ({
                url: `area-visit`, // Use chatId dynamically in the URL
                method: 'POST',
                body: body, // Use body key to send data in the POST request
            }),
        }),
        createSquad: builder.mutation({
            query: (body) => ({
                url: `squad/`, // Use chatId dynamically in the URL
                method: 'POST',
                body: body, // Use body key to send data in the POST request
            }),
        }),
        searchSquadByName : builder.query({
            query : (name) => ({
                url :`/squad/search/${name}`,
            })
        }),
        reportBug: builder.mutation({
            query: (body) => ({
                url: `/bug/`, // Use chatId dynamically in the URL
                method: 'POST',
                body: body, // Use body key to send data in the POST request
            }),
        }), 
          
    }),
});

export const {
    useGetPropertiesQuery,
    useGetPropertyByIdQuery,
    useGetBuyerByIdQuery,
    useCreateBuyerMutation,
    useUpdateBuyerMutation,
    useGetSquadByIdQuery,
    useGetSquadDealsBySquadIdQuery,
    useSquadByVibeMutation,
    useAddWaitingMemberInSquadMutation,
    useGetChatsByUserIdQuery,
    useAddMsgMutation,
    useCreateReferralMutation,
    useGetPartnerBusinessDataQuery,
    useCreateAreaVisitMutation,
    useCreateSquadMutation, 
    useSearchSquadByNameQuery,
    useReportBugMutation
} = apiSlice;
