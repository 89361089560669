import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { Box, Button, Input, Modal, Paper, Rating } from '@mui/material';
import { useSelector } from 'react-redux';
import { Check, PersonAdd } from '@mui/icons-material';
import { useCreateReferralMutation } from '../../Store/api/apiSlice';
import { LoadingButton } from '@mui/lab';

export default function SquadMembers() {
  const squadMembersData = useSelector((state) => state.squad);
  const buyer = useSelector((state) => state.buyer);
  const [squadMembers, setSquadMembers] = React.useState(null);
  const [isInviteModal, setInviteModal] = React.useState(false);
  const [inviteMobile, setInviteMobile] = React.useState(null);
  const [createReferral , {isLoading, error, data, isSuccess}] = useCreateReferralMutation()
  React.useEffect(() => {
    if (squadMembersData !== null) {
      setSquadMembers(squadMembersData?.members);
    }
  }, [squadMembersData]);

  const handleInvite = () => {
    setInviteModal(true);
  };

  const handleCloseInvite = () => {
    setInviteModal(false);
  };

  const submitInvite = () => {
    const id = String(Date.now());
    const currentTime = new Date().toISOString().slice(0, 19);
    const body = {
      id : id,
      time : currentTime,
      userId : buyer?.userId,
      mobile : inviteMobile,
      name : ""
    }
    createReferral(body).unwrap().then(
      (response) => {
        setInviteMobile("")
      }
    ).catch((err)=>{

    })
  }
  return (
    <Box sx={{ width: '100%', overflowX: 'hidden', display: 'flex', justifyContent:"center" }}>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',  // Ensure no wrapping of items
          overflowX: 'auto',  // Enable horizontal scrolling
          maxWidth: '100%',  // Ensure the container does not expand beyond the viewport width
          '&::-webkit-scrollbar': {
            width: '8px',
            height: '8px',
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 5px grey',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundImage: 'linear-gradient(90deg, #d0368a 0%, #708ad4 99%)',
            boxShadow: 'inset 2px 2px 5px 0 rgba(255, 255, 255, 0.5)',
            borderRadius: '100px',
          },
        }}
      >
        {squadMembers?.length < 9 && (
          <Box
            onClick={handleInvite}
            sx={{
              minWidth: '80px', // Set the same fixed width for the "Invite" button
              py: 2,
              px: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <Avatar
              sx={{
                alignSelf: 'center',
                my: 1,
                backgroundImage: 'linear-gradient(90deg, #d0368a 0%, #708ad4 99%)',
              }}
            >
              <PersonAdd />
            </Avatar>
            <Typography variant="caption">Invite</Typography>
          </Box>
        )}
        {squadMembers && squadMembers.map((member, index) => (
          <Box
            key={index}
            sx={{
              minWidth: '80px', // Set a fixed width for each item
              py: 2,
              px: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Avatar
              sx={{ alignSelf: 'center', my: 1 }}
              alt={member?.name}
              src="/static/images/avatar/1.jpg"
            />
            {!member?.isAdmin && (
              <Rating
                readOnly
                sx={{
                  color: '#9dccc6',
                  fontSize: '0.8rem',
                  alignSelf: 'center',
                  my: 1,
                }}
                value={member?.ratings}
              />
            )}
            <Typography variant="body2">{member?.name}</Typography>
          </Box>
        ))}
        
      </Box>

      <Modal
        open={isInviteModal}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        onClose={handleCloseInvite}
      >
        <Paper sx={{ height: '35%', p: 2, textAlign: 'center', borderRadius: 3 }}>
          <img height={80} src="/gif/discount.gif" alt="" />
          <Typography sx={{ py: 1 }}>Invite & Earn</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Input value={inviteMobile} onChange={(e)=>{setInviteMobile(e.target.value)}}  placeholder="Enter Mobile Number" />
            {error ? <Typography variant='caption' sx={{color:"red"}}>Error Inviting, Please try again.</Typography> :
            
            isLoading ? <LoadingButton loading={true} /> : 

            isSuccess ? <Typography variant='caption' sx={{color:"green"}}>Invited to join the squad !</Typography> :
            <Typography></Typography>
          }
          <Button sx={{
                        backgroundImage: 'linear-gradient(90deg, #d0368a 0%, #708ad4 99%)', 
                        color:"white", 
                        textTransform:"none", 
                        width:"80%", 
                        maxWidth:20,
                        alignSelf:"center"
                      }}  
                      onClick={submitInvite}>
                        Submit
          </Button> 
            
          </Box>
        </Paper>
      </Modal>
    </Box>
  );
}
