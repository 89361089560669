import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import { useAuth0 } from "@auth0/auth0-react";
import Protected from "./inc/Protected";
import Profile from "./components/profile";
import Payments from "./components/payments";
import Community from "./components/community";
import Create from "./components/create";
import BugReport from "./components/bug";
import Property from "./pages/Property";
import Properties from "./pages/Properties";
import SuggestProject from "./components/projectSuggestion/SuggestProject";
import SquadOverview from "./inc/SquadOverview";
import JoinSquad from "./components/joinSquad/JoinSquad";
import SquadSwipe from "./components/joinSquad/SquadSwipe";
import Chats from "./pages/Chats";
import Chat from "./pages/Chat";


function App() {
  const { isAuthenticated } = useAuth0();
  
  return (
    <div className="App" style={{width:"100%", height:"100%"}}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}>
            <Route path="/" element={<Protected isAuthenticated={isAuthenticated}><Community /></Protected>}></Route>
            <Route path="suggest-project" element={<Protected isAuthenticated={isAuthenticated}><SuggestProject /></Protected>} />
            <Route path="deals" element={<Protected isAuthenticated={isAuthenticated}><Properties /></Protected>} />
            <Route path="deal" element={<Protected isAuthenticated={isAuthenticated}><Property /></Protected>} />
            <Route path="deals/:id" element={<Protected isAuthenticated={isAuthenticated}><Property /></Protected>} />
            <Route path="payments" element={<Protected isAuthenticated={isAuthenticated}><Payments /></Protected>} />
            <Route path="profile" element={<Protected isAuthenticated={isAuthenticated}><Profile /></Protected>} />
            <Route path="bug-report" element={<Protected isAuthenticated={isAuthenticated}><BugReport /></Protected>} />
            <Route path="squad-overview/:id" element={<Protected isAuthenticated={isAuthenticated}><SquadOverview /></Protected>} />
            <Route path="join-squad/:id" element={<Protected isAuthenticated={isAuthenticated}><SquadSwipe /></Protected>} />
            <Route path="chats" element={<Protected isAuthenticated={isAuthenticated}><Chats /></Protected>} />
            <Route path="chats/:id" element={<Protected isAuthenticated={isAuthenticated}><Chat /></Protected>} />
          </Route>
          {/* Handle unmatched routes */}
          <Route path="*" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
