import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, Paper, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetChatsByUserIdQuery } from '../Store/api/apiSlice';
import { setChats } from '../Store/slices/chatDataSlice';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import ChatTopbar from '../inc/ChatTopbar';

const Chats = () => {
    const chatsDataSelector = useSelector(state => state.chatsData.chatsData);
    const buyer = useSelector(state => state.buyer);
    const [chatsData, setChatsData] = useState([])
    const dispatch = useDispatch();
    const [userId, setUserId] = useState(null)

    const { data, error, isLoading } = useGetChatsByUserIdQuery(userId, {
        skip: userId === null || userId === undefined
      });
    
    useEffect(()=>{
        setChatsData(chatsDataSelector)
    },[chatsDataSelector])
    useEffect(()=>{
        console.log("userIdSelector", buyer?.userId );
        if(buyer?.userId !== null) {
            setUserId(buyer?.userId)
        }
    },[buyer])
    // useEffect(()=>{
    //     console.log("userId" , userId);
    // },[userId])
    const navigate = useNavigate()
    const openChat = (id) => {
        navigate(`/chats/${id}`)
    };

    useEffect(() => {
        if (data?.chats) {
            dispatch(setChats(data.chats));
        }
    }, [isLoading, data]);

    return (
        <Box sx={{ flex: 1, marginHorizontal: 10, marginVertical: 10 }}>
            {error ? (
                <Paper sx={{ marginVertical: 30, paddingVertical: 30, width: "80%", borderRadius: 20, alignSelf: "center" }}>
                    <Typography sx={{ color: "black", textAlign: "center" }} >Error fetching chats. Please reload</Typography>
                </Paper>
            ) : isLoading ? (
                <Paper sx={{ marginVertical: 30, paddingVertical: 30, width: "80%", borderRadius: 20, alignSelf: "center" }}>
                    <Typography sx={{ color: "black", textAlign: "center" }} > <LoadingButton loading={true} />   Loading chats...</Typography>
                </Paper>
            ) : chatsData.length === 0 ? (
                <Paper sx={{ marginVertical: 30, paddingVertical: 30, width: "80%", borderRadius: 20, alignSelf: "center" }}>
                    <Typography sx={{ color: "black", textAlign: "center" }} >   No Active Chats</Typography>
                </Paper>

            ) : (
                chatsData.map((chat) => (
                    <Paper onClick={() => openChat(chat.chatId)}>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar src='/gif/vlogger.gif' />
                            </ListItemAvatar>
                            <ListItemText primary={chat.chatId} secondary={chat?.messages?.[0].text} />
                             
                        </ListItem>
                    </Paper>
                ))
            )}
        </Box>

    );
};

export default Chats;
