import { Autocomplete, Box, Button, Grow, TextField, Typography, Zoom } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useCreateSquadMutation, useUpdateBuyerMutation } from '../Store/api/apiSlice';
import LoadingAnimation from './LoadingAnimation';
import ErrorAnimation from './ErrorAnimation';

const CreateSquad = () => {
    const [squadName, setSquadName] = useState(undefined)
    const [memberName, setMemberName] = useState(undefined)
    const [memberMobile, setMemberMobile] = useState(undefined)
    const buyer = useSelector(state => state.buyer)
    const [squadVibes, setSquadVibes] = useState([])
    const vibeList = ["test", "test2"]
    const [createSquad,
        { isLoading, error, isSuccess, data }
    ] = useCreateSquadMutation()
    const [updateBuyer,
        {
            isLoading: updateBuyerLoading,
            error: updateBuyerError,
            isSuccess: updateBuyerSuccess,
            data: updateBuyerData
        }
    ] = useUpdateBuyerMutation()
    const onCreate = () => {
        const randomNumber = Math.random() * 1000; // Generate a random number between 0 and 100
        const randomNumberString = Math.floor(randomNumber).toString(); // Convert to integer and then to string
        const body = {
            squadId: squadName + randomNumberString || null,
            squadName: squadName || null,
            squadPitchUrl: null,
            vibes: squadVibes || [],
            timeline: [
                {
                    id: randomNumberString || null,
                    userId: buyer?.userId || null,
                    time: "2pm" || null,
                    action: "Deal Closed" || null,
                    icon_id: "check" || null,
                    short_desc: "Test" || null,
                }
            ] || [],
            members: [
                {
                    userId: buyer?.userId || null,
                    username: buyer?.username,
                    name: buyer?.name,
                    dob: buyer?.dob,
                    dobPretty: buyer?.dobPretty,
                    gender: buyer?.gender,
                    maritalStatus: buyer?.maritalStatus,
                    profession: buyer?.profession,
                    annualIncome: buyer?.annualIncome,
                    incomeRange: buyer?.incomeRange,
                    preferredPropertyType: buyer?.preferredPropertyType,
                    preferredLocation: buyer?.preferredLocation,
                    budget: buyer?.budget,
                    currentStatus: buyer?.currentStatus,
                    avatarImg: buyer?.avatarImg,
                    propertyPreferences: buyer?.propertyPreferences,
                    preferences: buyer?.preferences,
                    isAdmin: false,
                    isChannelPartner: false,
                    subscription: null,
                    ratings: null,
                    shortBio: null,
                    createdOn: null,
                    lastLogin: null
                }
            ] || [],
            membersWaiting: [],
            agent: {
                agentId: "",
                agentName: ""
            },
            projectsShortlisted: [],
            activityTimeline: [],
            createdOn: ""
        }
        // console.log(body);
        createSquad(body)
    }
    useEffect(() => {
        if (buyer && buyer?.id !== undefined && data?.id !== undefined) {
            const id = String(buyer?.id);  // Use 'id' to match what `updateBuyer` expects
            const squadId = data?.id;
    
            const body = {
                squadId: squadId
            };
    
            console.log('Buyer ID:', id);   // Ensure 'id' is correct
            console.log('Body:', body);     // Ensure body is correct
    
            // Correctly pass 'id' and 'body' to the mutation
            updateBuyer({ id, body })
        }
    }, [isSuccess, buyer, data]);  // Make sure `buyer` and `data` are in the dependency array
    useEffect(()=>{
        console.log("component loading");
        
    },[])
    useEffect(() => {
        if (updateBuyerSuccess) {
            const timeoutId = setTimeout(() => {
                window.location.reload();  // Reload the page after 2 seconds
            }, 2000);  // 2000 ms = 2 seconds
    
            // Optional: Clean up the timeout if the component unmounts or the effect is re-triggered
            return () => clearTimeout(timeoutId);
        }
    }, [updateBuyerSuccess]);
    
    return (
        <Box
            sx={{
                backgroundColor: "white",
                position: "absolute",
                width: "100%",
                maxWidth: 700,
                minHeight: 400,
                bottom: 0,

                paddingY: 5
            }}
        >
            {isLoading ? <LoadingAnimation /> :
                error ? <ErrorAnimation /> :
                    isSuccess ? <Box sx={{ background: "white", height: 300, width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                        <Zoom timeout={2000} in={true}>
                            <img
                                src="/gif/fireworks.gif"
                                alt="Error"
                                style={{ width: 150 }}
                            />
                        </Zoom>
                        <Grow timeout={3000} in={true}>
                            <Box sx={{ textAlign: "center" }}>
                                <Typography variant='h6' sx={{ color: "green" }}>Squad Created </Typography>
                                <Typography variant='body2' sx={{ color: "green" }}>Taking You to Squad Homepage. </Typography>

                            </Box>
                        </Grow>

                    </Box> :
                        <Box sx={{
                            display: "flex",
                            gap: 2,
                            flexDirection: "column",
                            alignItems: "center",
                        }}>
                            <Typography
                                sx={{
                                    textAlign: "center",
                                    mb: 2,
                                    width: "50%",
                                    alignSelf: "center",
                                    borderRadius: 2
                                }}
                                id="modal-modal-title"
                                variant="subtitle1"
                                component="h2">
                                Let's Create
                            </Typography>
                            <TextField value={squadName} onChange={(e) => { setSquadName(e.target.value) }} sx={{ width: "80%" }} label="Squad Name" />
                            <Autocomplete
                                multiple
                                onChange={(event, newValue) => {
                                    setSquadVibes(newValue);
                                }}
                                options={vibeList}
                                sx={{ width: "80%" }}
                                renderInput={(params) => <TextField {...params} label="Vibes" />}
                            />
                            <TextField value={memberName} onChange={(e) => { setMemberName(e.target.value) }} sx={{ width: "80%" }} label="Name" />
                            <TextField value={memberMobile} onChange={(e) => { setMemberMobile(e.target.value) }} sx={{ width: "80%" }} label="Mobile" />
                            <Button onClick={onCreate} variant='outlined' sx={{ backgroundImage: "linear-gradient(90deg, #d0368a 0%, #708ad4 99%)", color: "white", width: "80%", maxWidth: 200 }}>Create</Button>
                        </Box>
            }
        </Box>
    );
};

export default CreateSquad;