import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Box,
  Avatar,
  TextField,
  Fab,
  Button,
  Typography,
  Paper,
  Grid,
  Container,
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  Input,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Diamond, Home, DateRange, Pending,
  CurrencyRupee, Discount, AdfScanner, Phone, Cake, Work, Wc, Group, LocationOn,
  Psychology,
  Savings,
  Money,
  EmojiPeople,
  AlternateEmail,
  ArrowForward,
  Edit
} from "@mui/icons-material";
import axios from "axios";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Payments from "../payments";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { useUpdateBuyerMutation } from "../../Store/api/apiSlice";
import { setBuyer } from "../../Store/slices/buyersSlice";
import { setSnackbar } from "../../Store/slices/snackbarSlice";

const validationSchema = yup.object({
  email: yup.string("Enter your email").email("Enter a valid email").required("Email is required"),
  name: yup.string("Enter your name").required("Name is required"),
  mobile: yup.number()
    .typeError("That doesn't look like a phone number")
    .positive("A phone number can't start with a minus")
    .integer("A phone number can't include a decimal point")
    .required('A phone number is required'),
  dob: yup.date().typeError('The value must be a date (YYYY-MM-DD)').required('This field is required'),
  profession: yup.string("Enter your profession").required("Profession is required"),
  maritalStatus: yup.string("Enter your marital status").required("Marital Status is required"),
  gender: yup.string("Enter your gender").required("Gender is required"),
});

const Profile = () => {
  const { user } = useAuth0();
  const [editUser, setEditUser] = useState(false);
  const [userData, setUserData] = useState(null);
  const [ifFormSubmitted, setIfFormSubmitted] = useState(false);
  const [propertyBookings, setPropertyBookings] = useState([]);
  const [userPersonalDetails, setUserPersonalDetails] = useState([]);
  const [userPropertyDetails, setUserPropertyDetails] = useState([]);
  const [membership, setMemberships] = useState();
  const selector = useSelector((state) => state.buyer)
  const dispatch = useDispatch()


  const formik = useFormik({
    initialValues: {
      email: userData?.email || "",
      name: userData?.name || "",
      mobile: userData?.mobile || "",
      dob: userData?.dob || "",
      profession: userData?.profession || "",
      maritalStatus: userData?.maritalStatus || "",
      gender: userData?.gender || "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  useEffect(() => {
    setUserData(selector);
    setMemberships(selector.membership)
    console.log(selector.membership);
  }, [selector]);

  const [updateBuyer, { isLoading }] = useUpdateBuyerMutation()

  const handleUpdate = (updateKey, updateValue) => {
    if (userData) {
      // console.log(updateKey);
      const body = {
        [updateKey]: updateValue
      }

      if (userData?.id !== undefined) {
        const id = userData.id
        updateBuyer({ id, body }).unwrap().then((response) => {
          dispatch(setBuyer(response))
          dispatch(setSnackbar({
            message: `${updateKey} updated`,
            isOpen: true,
            action : "Close"
          }))
        })
      }
    }
  };

  const handleEdit = () => {
    setEditUser(true)
  };


  useEffect(() => {
    setUserPersonalDetails(
      [{
        key: "name",
        icon: <EmojiPeople />,
        value: userData?.name,
        placeholder: "Name",
        isEditing: false
      },
      {
        key: "email",
        icon: <AlternateEmail />,
        value: userData?.email,
        placeholder: "Email",
        isEditing: false
      },
      {
        key: "mobile",
        icon: <Phone />,
        value: userData?.mobile,
        placeholder: "Mobile",
        isEditing: false
      },
      {
        key: "dob",
        icon: <Cake />,
        value: userData?.dob,
        placeholder: "Birthdate",
        isEditing: false
      },
      {
        key: "gender",
        icon: <Wc />,
        value: userData?.gender,
        placeholder: "Gender",
        isEditing: false
      },
      {
        key: "maritalStatus",
        icon: <Group />,
        value: userData?.maritalStatus,
        placeholder: "Maratial Status",
        isEditing: false
      },
      {
        key: "profession",
        icon: <Work />,
        value: userData?.profession,
        placeholder: "Profession",
        isEditing: false
      },]
    )
    setUserPropertyDetails(
      [
        {
          key: "propertyType",
          icon: <Home />,
          value: userData?.propertyPreferences?.propertyType,
          placeholder: "Property Type",
        },
        {
          key: "locationPreferences",
          icon: <LocationOn />,
          value: userData?.propertyPreferences?.locationPreferences,
          placeholder: "Location Preferences",
        },
        {
          key: "amenities",
          icon: <Psychology />,
          value: userData?.propertyPreferences?.amenities,
          placeholder: "Amenities",
        },
        {
          key: "amenities",
          icon: <Savings />,
          value: userData?.budget.min,
          placeholder: "Min Budget",
        },
        {
          key: "amenities",
          icon: <Money />,
          value: userData?.budget.max,
          placeholder: "Max Budget",
        },
      ]
    )
  }, [userData])

  return (
    <Grid className="main container" sx={{ display: "flex", justifyContent: "center", width: "100%", alignSelf: "center" }} container >
      <Grid container sx={{ display: "flex", justifyContent: "center" }} spacing={3}>
        <Grid item xs={11} lg={11}>
          <Box sx={{ padding: 3, display: "flex", justifyContent: "center" }}>
            <Box position="relative">
              <Avatar
                variant="square"
                sx={{
                  width: 100, height: 100, borderRadius: "10px", zIndex: "100",
                  boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px"
                }}
                alt=""
                src={user.picture.length === 0 ? user.picture : "/gif/vlogger.gif"}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={11} lg={5}>
          <Paper sx={{ padding: 1 }}>
            <List>
              {userPersonalDetails && userPersonalDetails.map(
                (data) => {
                  return <ListItem secondaryAction={
                    <Box>
                      {
                        data?.value !== null && !editUser ?
                          <Button onClick={handleEdit}>
                            <Edit />
                          </Button> :
                          <Box>
                            {isLoading ? <LoadingButton loading={true} /> :
                              <Button onClick={() => handleUpdate(data?.key || null, formik.values[data.key] || null)}> {/* Pass the entire updated object */}
                                <ArrowForward />
                              </Button>
                            }
                          </Box>
                      }
                    </Box>
                  }>
                    <ListItemIcon>
                      {data.icon}
                    </ListItemIcon>
                    {data?.value !== null && !editUser ? (
                      <ListItemText primary={data?.value} />
                    ) : (
                      <Box sx={{ display: "flex" }}>
                        <Input
                          placeholder={data.placeholder}
                          value={formik.values[data.key] || ""}  // Dynamically bind the value using formik and the key
                          onChange={formik.handleChange}        // Use formik's handleChange
                          name={data.key}                       // Ensure formik knows which field to update
                          fullWidth
                          margin="normal"
                          error={formik.touched[data.key] && Boolean(formik.errors[data.key])}
                          helperText={formik.touched[data.key] && formik.errors[data.key]}
                        />

                      </Box>
                    )}

                  </ListItem>
                }
              )}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={11} lg={5}>
          <Paper sx={{ padding: 3 }}>
            <Typography variant="h6" gutterBottom>
              Preferred Property and Budget
            </Typography>
            <List>
              {userPropertyDetails && userPropertyDetails.map(
                (data) => {
                  return <ListItem secondaryAction={
                    <Box>
                      {
                        data?.value !== null && !editUser ?
                          <Button onClick={handleEdit}>
                            <Edit />
                          </Button> :
                          <Box>
                            {isLoading ? <LoadingButton loading={true} /> :
                              <Button onClick={() => handleUpdate(data?.key || null, formik.values[data.key] || null)}> {/* Pass the entire updated object */}
                                <ArrowForward />
                              </Button>
                            }
                          </Box>
                      }
                    </Box>
                  }>
                    <ListItemIcon>
                      {data.icon}
                    </ListItemIcon>
                    {data?.value !== null && !editUser ? (
                      <ListItemText primary={data?.value} />
                    ) : (
                      <Box sx={{ display: "flex" }}>
                        <Input
                          placeholder={data.placeholder}
                          value={formik.values[data.key] || ""}  // Dynamically bind the value using formik and the key
                          onChange={formik.handleChange}        // Use formik's handleChange
                          name={data.key}                       // Ensure formik knows which field to update
                          fullWidth
                          margin="normal"
                          error={formik.touched[data.key] && Boolean(formik.errors[data.key])}
                          helperText={formik.touched[data.key] && formik.errors[data.key]}
                        />

                      </Box>
                    )}

                  </ListItem>
                }
              )}
            </List>
          </Paper>
        </Grid>

        {/* <Grid item xs={11} lg={5}>
          <Paper sx={{ padding: 3 }}>
            <Typography variant="h6" gutterBottom>
              Property Bookings
            </Typography>
            <List>
              {propertyBookings.map((booking) => (
                <Box key={booking.id} mb={2}>
                  <ListItem>
                    <ListItemIcon>
                      <Home />
                    </ListItemIcon>
                    <ListItemText primary={`Property: ${booking.propertyName}`} />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <DateRange />
                    </ListItemIcon>
                    <ListItemText primary={`Booking Date: ${booking.bookingDate}`} />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <Pending />
                    </ListItemIcon>
                    <ListItemText primary={`Status: ${booking.status}`} />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CurrencyRupee />
                    </ListItemIcon>
                    <ListItemText primary={`Price: ${booking.price}`} />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <Discount />
                    </ListItemIcon>
                    <ListItemText primary={`Discount: ${booking.discount}`} />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <AdfScanner />
                    </ListItemIcon>
                    <ListItemText primary={`Location: ${booking.location}`} />
                  </ListItem>
                </Box>
              ))}
            </List>
          </Paper>
        </Grid> */}

        <Grid item xs={11} lg={5}>
          <Paper sx={{ padding: 3 }}>
            <Typography variant="h6" gutterBottom>
              <Diamond /> Subscriptions
            </Typography>
            <List>
              {membership?.status !== undefined ?
              <Box key={membership?.memberId} mb={2}>
                <ListItem>
                  <ListItemText primary={`Subscription Name: ${membership?.membershipType}`} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={`Start Date: ${membership?.startDate}`} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={`End Date: ${membership?.endDate}`} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={`Amount Paid: ${membership?.price}`} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={`Status: ${membership?.membershipStatus}`} />
                </ListItem>
              </Box> : 
              <Box>
                <Typography >No Active Subscriptions</Typography>
              </Box>
              }
            </List>
          </Paper>
        </Grid>

        <Grid item xs={11} lg={11}>
          <Paper sx={{ padding: 3 }}>
            <Typography variant="h6" gutterBottom>
              Payments
            </Typography>
            <Payments />
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Profile;
