import { Autocomplete, Box, Button, Grow, Slide, TextField, Typography, useTheme, Zoom } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useCreateAreaVisitMutation } from '../Store/api/apiSlice';
import LoadingAnimation from './LoadingAnimation'
import { CheckCircleOutline } from '@mui/icons-material';
import ErrorAnimation from './ErrorAnimation';
const CreateAreaVisit = () => {
    const partnerBusinessData = useSelector(state => state.businessData.data)
    const squad = useSelector(state => state.squad)
    const buyer = useSelector(state => state.buyer)
    const [createAreaVisit, { isLoading, isSuccess, error }] = useCreateAreaVisitMutation()
    const [cityList, setCityList] = React.useState([]);
    const [selectedCity, setSelectedCity] = React.useState([]);
    const [areaList, setAreaList] = React.useState([]);
    const theme = useTheme()
    const [selectedRoute, setSelectedRoute] = React.useState([]);
    const [durationList, setDurationList] = React.useState([
        { label: '100 min', durationInMin: 100 },
        { label: '200 min', durationInMin: 200 },
        { label: '300 min', durationInMin: 300 },
        { label: '400 min', durationInMin: 400 },
    ]);
    const [selectedDuration, setSelectedDuration] = React.useState([]);
    const [dateList, setDateList] = React.useState([
        { label: '23 Aug | Mon', year: 1994 },
        { label: '24 Aug | Tue', year: 1972 },
        { label: '25 Aug | Wed', year: 1974 },
        { label: '26 Aug | Thu', year: 2008 },
    ]);
    const [timeList, setTimeList] = React.useState([
        { label: '9 PM', year: 1994 },
        { label: '10 PM', year: 1972 },
        { label: '11 PM', year: 1974 },
        { label: '12 PM', year: 2008 },
    ]);
    const [propertiesList, setPropertiesList] = React.useState([]);
    const [selectedProperties, setSelectedProperties] = React.useState([]);

    useEffect(() => {
        if (partnerBusinessData?.cities) {
            setCityList(partnerBusinessData?.cities)
        }
        if (partnerBusinessData?.routes) {
            setAreaList(partnerBusinessData?.routes)
        }
        if (partnerBusinessData?.properties) {
            partnerBusinessData?.properties.map((property) => {
                propertiesList.push({ label: property?.propertyName, ...property })
            })

        }
        // console.log(partnerBusinessData);

    }, [partnerBusinessData])

    const onCreate = () => {
        const randomNumber = Math.random() * 1000; // Generate a random number between 0 and 100
        const randomNumberString = Math.floor(randomNumber).toString(); // Convert to integer and then to string

        const body = {
            agentId: partnerBusinessData?.email,
            visitId: `${squad?.squadId}${randomNumberString}`,
            squadId: buyer?.squadId,
            buyerName: buyer?.name,
            membershipIcon: buyer?.membershipIcon,
            pay: 99,
            durationInMin: selectedDuration?.durationInMin,
            tourMap: selectedRoute,
            acceptedByAgent: false,
            properties: selectedProperties,
            participants: [{
                userId: buyer?.userId,
                username: buyer?.username,
                name: buyer?.name,
                gender: buyer?.gender,
                maritalStatus: buyer?.maritalStatus,
                profession: buyer?.profession,
                annualIncome: buyer?.annualIncome,
                incomeRange: buyer?.incomeRange,
                preferredPropertyType: buyer?.preferredPropertyType,
                preferredLocation: buyer?.preferredLocation,
                budget: buyer?.budget,
                currentStatus: buyer?.currentStatus,
                avatarImg: buyer?.avatarImg
            }],
            status: "pending",
            visitTime: "2024-09-10T10:00:00",
            isAgentOnline: false
        };

        console.log(body); // Log the whole number

        createAreaVisit(body)
    };



    return (

        <>
            {isLoading ?
                <Box sx={{ background: "white", height: 300, width: "100%" }}>
                    <LoadingAnimation />
                </Box> :
                isSuccess ?
                    <Box sx={{ background: "white", height: 300, width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                        <Zoom timeout={2000} in={true}>
                            <img
                                src="/gif/checkGreen.gif"
                                alt="Success"
                                style={{ width: 100 }}
                            />
                        </Zoom>
                        <Grow timeout={3000} in={true}><Typography variant='h6' sx={{  color: "green" }}>Visit Created</Typography></Grow>
                    </Box> :
                error ? <Box sx={{ background: "white", height: 300, width: "100%",}}>
                    <ErrorAnimation />
                </Box> :
                    <Box sx={{ 
                        backgroundColor: "white", 
                        p: 3, 
                        width: "100%", 
                        maxWidth: 600, 
                        display: "flex", 
                        flexDirection: "column", 
                        gap: 1, 
                        justifyContent: "center"}}>
                        <Typography
                            sx={{
                                textAlign: "center",
                                mb: 2,
                                width: "50%",
                                alignSelf: "center",
                                borderRadius: 2
                            }}
                            id="modal-modal-title"
                            variant="subtitle1"
                            component="h2">
                            Let's Create
                        </Typography>
                        <Autocomplete
                            disablePortal
                            options={cityList}
                            sx={{ width: "100%" }}
                            renderInput={(params) => <TextField {...params} label="Select City" />}
                        />
                        <Autocomplete
                            multiple
                            value={selectedRoute}
                            onChange={(event, newValue) => {
                                setSelectedRoute(newValue);
                            }}
                            options={areaList}
                            sx={{ width: "100%" }}
                            renderInput={(params) => <TextField {...params} label="Select Route" />}
                        />
                        <Autocomplete
                            disablePortal
                            value={selectedDuration}
                            onChange={(event, newValue) => {
                                setSelectedDuration(newValue);
                            }}
                            options={durationList}
                            sx={{ width: "100%" }}
                            renderInput={(params) => <TextField {...params} label="Duration" />}
                        />
                        <Autocomplete
                            disablePortal
                            options={dateList}
                            sx={{ width: "100%" }}
                            renderInput={(params) => <TextField {...params} label="Date" />}
                        />
                        <Autocomplete
                            disablePortal
                            options={timeList}
                            sx={{ width: "100%" }}
                            renderInput={(params) => <TextField {...params} label="Time" />}
                        />
                        <Autocomplete
                            multiple
                            value={selectedProperties}
                            onChange={(event, newValue) => {
                                setSelectedProperties(newValue);
                            }}
                            options={propertiesList}
                            sx={{ width: "100%" }}
                            renderInput={(params) => <TextField {...params} label="Properties" />}
                        />
                        <Button onClick={onCreate} variant='outlined' sx={{ backgroundImage: "linear-gradient(90deg, #d0368a 0%, #708ad4 99%)", color: "white" }}>Create</Button>

                    </Box>
            }
        </>

    );
};

export default CreateAreaVisit;