import { AccountCircle, Add, AssistantPhotoOutlined, BugReport, Chat, EmojiPeople, Explore, GridView, Home, MenuBookTwoTone, More, Search } from '@mui/icons-material';
import { AppBar, Box, Button, Fab, IconButton, Menu, MenuItem, styled, Toolbar, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const StyledFab = styled(Fab)({
  position: 'absolute',
  zIndex: 1,
  top: -30,
  left: 0,
  right: 0,
  margin: '0 auto',
  backgroundImage: "linear-gradient(90deg, #d0368a 0%, #708ad4 99%)",
  color: "#fff"
});

const Appbar_Bottom = () => {
  const [open, setOpen] = useState(false);
  const selector = useSelector(state => state.buyer)

  const handleClick = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const navigate = useNavigate()
  const handleNavigation = (route) => {
    navigate(route)
  }


  const menuData =
    [
      { label: "Home", icon: <Home sx={{ marginRight: 1, color: "#708ad4" }} />, newRoute: '/' },
      { label: "Chats", icon: <Chat sx={{ marginRight: 1, color: "#708ad4" }} />, newRoute: 'chats' },
      { label: "Profile", icon: <EmojiPeople sx={{ marginRight: 1, color: "#708ad4" }} />, newRoute: 'profile' },
    ]
  return (
    <AppBar position="fixed" sx={{ top: 'auto', bottom: 0, backgroundColor: "#fff", display:"flex", flexDirection:"row", width:"100%" }}>
    {menuData.map((menu) => {
            return <Button  sx={{ display: "flex", flexDirection: "column", width: "33%", textTransform:"none", justifyContent:"center" }} onClick={() => { handleNavigation(menu.newRoute) }}>
              <Typography  >{menu.icon}</Typography>
              <Typography  >{menu.label}</Typography>
            </Button>
          })}
    </AppBar>
  );
};

export default Appbar_Bottom;
