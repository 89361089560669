import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAddMsgMutation } from '../Store/api/apiSlice';
import { changeChatName, changeChatOpen } from '../Store/slices/rootSlice';
import { Autocomplete, Box, Button, Grow, Input, Menu, MenuItem, MenuList, Modal, TextField, Typography } from '@mui/material';
import { Add, Check, Close, Send } from '@mui/icons-material';
import CreateAreaVisit from '../inc/CreateAreaVisit';



const Chat = () => {
    const [messagesLocal, setMessages] = useState([]);
    const chatsData = useSelector(state => state.chatsData.chatsData);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);
    const userId = useSelector(state => state.buyer.userId)
    const userName = useSelector(state => state.buyer.name)
    const dispatch = useDispatch();
    const { id } = useParams();
    const [addMsg, { isLoading }] = useAddMsgMutation();
    const [newMsg, setNewMsg] = useState('');
    const [isSent, setIsSent] = useState(Boolean);
    const [error, setError] = useState(String);
    const scrollViewRef = useRef(null);
    const [openModal, setOpenModal] = React.useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    useEffect(() => {
        dispatch(changeChatOpen(true));

        return () => {
            dispatch(changeChatOpen(false));
        };
    }, []);

    useEffect(() => {
        if (chatsData && id) {
            const chat = chatsData.find((chat) => chat.chatId === id);
            if (chat && Array.isArray(chat.messages)) {
                // const transformedMessages = chat.messages.map((message) => ({
                //     _id: message.messageId,
                //     text: message.text,
                //     senderId: message.senderId,
                //     senderName: message.senderName,
                //     createdAt: new Date(message.time),
                //     user: {
                //         _id: message.userId,
                //         name: message.name,
                //         avatar: message.avatar
                //     },
                //     status: message.status
                // }));
                setMessages(chat.messages);
                dispatch(changeChatName(chat.chatId));
            } else {
                setMessages([]);
            }
        }
    }, [chatsData, id]);

    const onSend = () => {
        if (newMsg?.trim() && id) {
            const currentTime = new Date().toISOString().slice(0, 19);
            const messageId = String(Date.now());
            const formattedMsg = {
                messageId,
                senderId: userId,
                senderName: userName,
                text: newMsg,
                time: currentTime,
                status: "sent"
            };

            addMsg({ chatId: id, msg: formattedMsg })
                .unwrap().then((response) => {
                    setIsSent(true)
                    setMessages((prevMessages) => [...prevMessages, formattedMsg]);

                }).catch((err) => {
                    setError(err)
                })

            return '';
        }
        // console.log('new mgs', newMsg);
        return newMsg;
    }
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const onDismiss = () => { setError("") }
    return (
        <Box sx={{ height: "100%" }}>
            {id ? (
                <Box
                    sx={{ height: "100%", }}
                >
                    <Box sx={styles.chatContainer} >
                        {messagesLocal.map((message) => (
                            <Box key={message.messageId} sx={message.senderId === userId ? styles.rightMsgContainer : styles.msgContainer}>
                                <Box elevation={1} sx={message.senderId === userId ? styles.msgWrapperLeft : styles.msgWrapperRight}>
                                    <Typography variant='caption'>{message.senderName}</Typography>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                                        <Typography variant='body2'>{message.text}</Typography>
                                        {isSent && <Check />}
                                        {error && <Close />}
                                    </Box>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                    <Box sx={styles.inputBox}>
                        <Input
                            sx={{ mr: 3, ml: 1, }}
                            fullWidth
                            value={newMsg}
                            placeholder='Type here ...'
                            onChange={(e) => { setNewMsg(e.target.value) }}
                            endAdornment={<Box sx={{ backgroundColor: "#1fc227", p: 1, m: 1, borderRadius: 20 }} onClick={onSend}><  Send sx={{ color: "white", height: 15 }} /></Box>}
                            startAdornment={<Button
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                                sx={{ pr: 1 }}><Add /></Button>}
                        />
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}

                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',

                            }}

                        >
                            <MenuItem onClick={handleOpenModal}>Create Area Visit</MenuItem>
                            <MenuItem onClick={handleClose}>Suggest Partner</MenuItem>
                            <MenuItem onClick={handleClose}>Suggest Builder</MenuItem>
                        </Menu>
                    </Box>
                    <Modal
                        open={openModal}
                        onClose={handleCloseModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        sx={{ display: "flex", alignItems: "end", justifyContent: "center" }}
                    >
                        <CreateAreaVisit />
                    </Modal>
                </Box>
            ) : (
                <Typography>No chat available</Typography>
            )}
        </Box>
    );
};

const styles = {
    flexContainer: {
        height: "100%",
    },
    rightMsgContainer: {
        alignSelf: "flex-end",
        py: 1
    },
    msgContainer: {
        py: 1
    },
    msgWrapperLeft: {
        maxWidth: "100%",
        borderRadius: 1,
        backgroundColor: "#e6f2e9",
        marginVertical: 10,
        py: 1,
        px: 1,
    },
    msgWrapperRight: {
        py: 1,
        px: 2,
        maxWidth: "70%",
        borderRadius: 1,
        backgroundColor: "#f5f5f5",
        marginVertical: 10
    },

    chatContainer: {

        height: "70%",
        display: "flex",
        flexDirection: "column",
    },
    inputBox: {

        backgroundColor: "#fff",
        width: "100%",
        bottom: 80,
        position: "fixed",
        display: 'flex',

    },
}

export default Chat;
